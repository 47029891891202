import request from "@/utils/request";
const api = {
  getquotationlist: "/dsmcore/api/quotation/list", // 查询报价列表
  addquotation: "/dsmcore/api/quotation/add", //添加报价
  updatequotation: "/dsmcore/api/quotation/update", //编辑报价
  deletequotation: "/dsmcore/api/quotation/delete", //删除报价
  getquotationDetaillist: "/dsmcore/api/quotationDetail/list", //查询报价详细信息
  addquotationDetaillist: "/dsmcore/api/quotationDetail/add", //添加报价详细信息
  updatequotationDetaillist: "/dsmcore/api/quotationDetail/update", //更新报价详细信息
  deletequotationDetaillist: "/dsmcore/api/quotationDetail/delete", //删除报价详细信息
  fileupload: "/files/api/file/upload", //上传文件
  filedelete: "/files/api/file/delete", //删除文件
  getcontractlist: "/dsmcore/api/contract/list", // 获取合同列表
  contractadd: "/dsmcore/api/contract/add", // 新增合同
  contractupdate: "/dsmcore/api/contract/update", // 编辑合同
  contractdelete: "/dsmcore/api/contract/delete", // 删除合同
  generateContractQuotation: "/dsmcore/api/quotation/generateContractQuotation", //转化进度--生成签约报价
  fileDownload: "/files/api/file", //下载
};
export function GetQuotationList(parameter) {
  return request({
    url: api.getquotationlist,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
export function AddQuotation(parameter) {
  return request({
    url: api.addquotation,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function UpdateQuotation(parameter) {
  return request({
    url: api.updatequotation,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeleteQuotation(parameter) {
  return request({
    url: api.deletequotation,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
export function GetQuotationDetailList(parameter) {
  return request({
    url: api.getquotationDetaillist,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
export function AddQuotationDetailList(parameter) {
  return request({
    url: api.addquotationDetaillist,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function UpdateQuotationDetailList(parameter) {
  return request({
    url: api.updatequotationDetaillist,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeleteQuotationDetailList(parameter) {
  return request({
    url: api.deletequotationDetaillist,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}
export function FileUpload(parameter) {
  return request({
    url: api.fileupload,
    method: "post",
    // params: parameter,
    // headers: { "Content-Type": "multipart/form-data" },
    data: parameter,
  });
}
export function FileDelete(parameter) {
  return request({
    url: api.filedelete,
    method: "post",
    params: parameter,
    // headers: { "Content-Type": "multipart/form-data" },
    // data: parameter,
  });
}
export function GetContractList(parameter) {
  return request({
    url: api.getcontractlist,
    method: "post",
    params: parameter,
  });
}
export function ContractAdd(parameter) {
  return request({
    url: api.contractadd,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function ContractUpdate(parameter) {
  return request({
    url: api.contractupdate,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function ContractDelete(parameter) {
  return request({
    url: api.contractdelete,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}

export function GenerateContractQuotation(parameter) {
  return request({
    url: api.generateContractQuotation,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function FileDownload(parameter) {
  return request({
    url: api.fileDownload,
    method: "get",
    params: parameter,
    // data: parameter,
  });
}
