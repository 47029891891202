import request from "@/utils/request";
const api = {
  getpackageTemplatelist: "/dsmcore/api/packageTemplate/list", // 查询套餐模版列表
  addpackageTemplate: "/dsmcore/api/packageTemplate/add", //添加套餐模版接口
  updatepackageTemplate: "/dsmcore/api/packageTemplate/update", //更新套餐模版接口
  deletepackageTemplate: "/dsmcore/api/packageTemplate/delete", //删除套餐模版接口
  getpackageDetail: "/dsmcore/api/packageDetail/list", //查询套餐详情信息
  addpackageDetail: "/dsmcore/api/packageDetail/addList", //添加套餐详情信息
  updatepackageDetail: "/dsmcore/api/packageDetail/updateBatch", //更新套餐详情信息
  deletepackageDetail: "/dsmcore/api/packageDetail/delete", //删除套餐详情信息
  computepackageDetail: "/dsmcore/api/packageDetail/compute", //套餐内施工项计算
};
export function GetPackageTemplateList(parameter) {
  return request({
    url: api.getpackageTemplatelist,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
export function AddPackageTemplate(parameter) {
  return request({
    url: api.addpackageTemplate,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function UpdatePackageTemplate(parameter) {
  return request({
    url: api.updatepackageTemplate,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeletePackageTemplate(parameter) {
  return request({
    url: api.deletepackageTemplate,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
export function GetPackageDetail(parameter) {
  return request({
    url: api.getpackageDetail,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
export function AddPackageDetail(parameter) {
  return request({
    url: api.addpackageDetail,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function UpdatePackageDetail(parameter) {
  return request({
    url: api.updatepackageDetail,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeletePackageDetail(parameter) {
  return request({
    url: api.deletepackageDetail,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}

export function ComputePackageDetail(parameter, data) {
  return request({
    url: api.computepackageDetail,
    method: "post",
    params: parameter,
    data: data,
  });
}
