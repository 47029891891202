<template>
  <a-form-model
    :model="form"
    layout="horizontal"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    ref="ruleForm"
    :colon="false"
    :rules="rules"
  >
    <div class="title">基本信息</div>
    <a-form-model-item label="所属公司" prop="qdBcId">
      <a-select v-model="form.qdBcId" placeholder="请输入" disabled>
        <a-select-option
          v-for="item in GetCompanyList"
          :key="item.bc_id"
          :value="item.bc_id"
        >
          {{ item.bc_name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="所属施工工序">
      <!--   :disabled="JSON.parse(this.record).wpId ? true : false" -->
      <a-select
        v-model="form.qdWpId"
        placeholder="请选择"
        :disabled="type == 'edit'"
      >
        <a-select-option
          v-for="item in WorkProceList"
          :key="item.wpId"
          :value="item.wpId"
        >
          {{ item.wpName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="所属施工项类型">
      <a-tree-select
        placeholder="请输入"
        :disabled="type == 'edit'"
        v-model="form.PtId"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="ProjectTypeList"
        tree-default-expand-all
        :replaceFields="{
          key: 'ptId',
          title: 'ptName',
          value: 'ptId',
          children: 'children',
        }"
      >
      </a-tree-select>
    </a-form-model-item>
    <a-form-model-item label="施工项名称">
      <a-select
        v-model="form.qdPId"
        placeholder="请输入"
        :disabled="type == 'edit'"
      >
        <a-select-option
          v-for="item in ProjectList"
          :key="item.pid"
          :value="item.pid"
        >
          {{ item.pname }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <!--  -->
    <a-form-model-item label="是否属于增减项" v-if="type == 'add'">
      <a-select v-model="form.qdAttribute" placeholder="请选择">
        <a-select-option
          v-for="item in AddAttributeList"
          :key="item.value"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="是否属于增减项" v-if="type == 'edit'">
      <a-select
        v-model="form.qdAttribute"
        placeholder="请选择"
        :disabled="type == 'edit'"
      >
        <a-select-option
          v-for="item in AttributeList"
          :key="item.value"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="施工量">
      <a-input v-model="form.qdQuantity" placeholder="请输入" />
    </a-form-model-item>
    <!-- <a-form-model-item label="工程量计量单位">
      <a-select v-model="form.qdUnit" placeholder="请选择">
        <a-select-option
          v-for="item in qdUnitSelectValue"
          :key="item.value"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </a-form-model-item> -->
    <a-form-model-item label="核算单位单价(元)">
      <a-tooltip placement="bottom">
        <template slot="title">
          <span>会随单位辅材量提供方变化，默认等于销售单价</span>
        </template>
        <a-icon type="exclamation-circle" theme="filled" />
      </a-tooltip>
      <a-input v-model="form.qdPrice" disabled />
    </a-form-model-item>
    <a-form-model-item label="主材清单：">
      <div class="table">
        <a @click="() => addItemMain()">新增主材</a>
        <a-table
          :columns="columnsMain"
          :data-source="dataMain"
          bordered
          key="id"
          :scroll="{ x: 700 }"
        >
          <template
            v-for="col in ['maMId', 'maQuantity', 'maProvider', 'maAttribute']"
            :slot="col"
            slot-scope="text, record"
          >
            <div :key="col">
              <a-select
                placeholder="请选择"
                v-if="col == 'maMId'"
                v-model="record.maMId"
                @change="(e) => handleChangeMain(e, record.id, col)"
              >
                <a-select-option
                  v-for="opt in ProjectMaterialListMain"
                  :key="opt.pmMId"
                  :value="opt.pmMId"
                  >{{ opt.tmaterial.mName }}</a-select-option
                >
              </a-select>

              <a-input
                placeholder="用量"
                v-if="col == 'maQuantity'"
                v-model="record.maQuantity"
                :value="text"
              />

              <a-select
                placeholder="请选择"
                v-if="col == 'maProvider'"
                v-model="record.maProvider"
                @change="(e) => handleChangeMain(e, record.id, col)"
              >
                <a-select-option
                  v-for="opt in MaterialSupplier"
                  :key="opt.value"
                  :value="opt.value"
                  >{{ opt.label }}</a-select-option
                >
              </a-select>
              <!-- 材料属性包含在施工项内时 -->
              <div v-if="col == 'maAttribute' && record.maContain == '1'">
                <a-select
                  placeholder=""
                  v-if="col == 'maAttribute' && record.maAttribute == '1'"
                  v-model="record.maAttribute"
                  @change="(e) => handleChangeMain(e, record.id, col)"
                  :disabled="record.maAttribute == '1'"
                >
                  <a-select-option
                    v-for="opt in MaterialAttribute"
                    :key="opt.value + ''"
                    :value="opt.value + ''"
                    >{{ opt.label }}</a-select-option
                  >
                </a-select>
                <a-select
                  placeholder=""
                  v-if="col == 'maAttribute' && record.maAttribute != '1'"
                  v-model="record.maAttribute"
                  @change="(e) => handleChangeMain(e, record.id, col)"
                >
                  <a-select-option
                    v-for="opt in NoMaterialAttribute"
                    :key="opt.value + ''"
                    :value="opt.value + ''"
                    >{{ opt.label }}</a-select-option
                  >
                </a-select>
              </div>

              <!-- 材料属性不包含在施工项内时 只能有增加项-->
              <a-select
                placeholder=""
                v-if="col == 'maAttribute' && record.maContain == '0'"
                v-model="record.maAttribute"
                @change="(e) => handleChangeMain(e, record.id, col)"
                disabled
              >
                <a-select-option
                  v-for="opt in AddMaterialAttribute"
                  :key="opt.value + ''"
                  :value="opt.value + ''"
                  >{{ opt.label }}</a-select-option
                >
              </a-select>
            </div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="editable-row-operations">
              <span>
                <a-icon type="plus" @click="() => addItemMain(record.maMId)" />

                <a-icon
                  v-if="record.maAttribute != '1'"
                  type="minus"
                  @click="() => deleteItemMain(record.id, record.maAttribute)"
                />
              </span>
            </div>
          </template>
        </a-table>
      </div>
    </a-form-model-item>
    <a-form-model-item label="辅材清单：">
      <div class="table">
        <a @click="() => addItemAux()">新增辅材</a>
        <a-table
          :columns="columnsMain"
          :data-source="dataAux"
          bordered
          key="id"
          :scroll="{ x: 700 }"
        >
          <template
            v-for="col in ['maMId', 'maQuantity', 'maProvider', 'maAttribute']"
            :slot="col"
            slot-scope="text, record"
          >
            <div :key="col">
              <a-select
                placeholder="请选择"
                v-if="col == 'maMId'"
                v-model="record.maMId"
                @change="(e) => handleChangeAux(e, record.id, col)"
              >
                <a-select-option
                  v-for="opt in ProjectMaterialListAux"
                  :key="opt.pmMId"
                  :value="opt.pmMId"
                  >{{ opt.tmaterial.mName }}</a-select-option
                >
              </a-select>

              <a-input
                placeholder="用量"
                v-if="col == 'maQuantity'"
                v-model="record.maQuantity"
                :value="text"
              />
              <div v-if="col == 'maProvider'">公司</div>
              <!-- <a-select
                placeholder="请选择"
                v-if="col == 'maProvider'"
                v-model="record.maProvider"
                @change="(e) => handleChangeAux(e, text, col)"
                disabled
              >
                <a-select-option
                  v-for="opt in MaterialSupplier"
                  :key="opt.value"
                  :value="opt.value"
                  >{{ opt.label }}</a-select-option
                >
              </a-select> -->
              <!-- 材料属性包含在施工项内时 -->
              <div v-if="col == 'maAttribute' && record.maContain == '1'">
                <a-select
                  placeholder=""
                  v-if="col == 'maAttribute' && record.maAttribute == '1'"
                  v-model="record.maAttribute"
                  @change="(e) => handleChangeAux(e, record.id, col)"
                  :disabled="record.maAttribute == '1'"
                >
                  <a-select-option
                    v-for="opt in MaterialAttribute"
                    :key="opt.value + ''"
                    :value="opt.value + ''"
                    >{{ opt.label }}</a-select-option
                  >
                </a-select>
                <a-select
                  placeholder=""
                  v-if="col == 'maAttribute' && record.maAttribute != '1'"
                  v-model="record.maAttribute"
                  @change="(e) => handleChangeAux(e, record.id, col)"
                >
                  <a-select-option
                    v-for="opt in NoMaterialAttribute"
                    :key="opt.value + ''"
                    :value="opt.value + ''"
                    >{{ opt.label }}</a-select-option
                  >
                </a-select>
              </div>
              <!-- 材料属性不包含在施工项内时 只能有增加项-->
              <a-select
                placeholder=""
                v-if="col == 'maAttribute' && record.maContain == '0'"
                v-model="record.maAttribute"
                @change="(e) => handleChangeMain(e, record.id, col)"
                disabled
              >
                <a-select-option
                  v-for="opt in AddMaterialAttribute"
                  :key="opt.value + ''"
                  :value="opt.value + ''"
                  >{{ opt.label }}</a-select-option
                >
              </a-select>
            </div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="editable-row-operations">
              <span>
                <a-icon type="plus" @click="() => addItemAux(record.maMId)" />

                <a-icon
                  v-if="record.maAttribute != '1'"
                  type="minus"
                  @click="() => deleteItemAux(record.id, record.maAttribute)"
                />
              </span>
            </div>
          </template>
        </a-table>
      </div>
    </a-form-model-item>
    <!-- <a-form-model-item label="备注说明（选填）">
      <a-input v-model="form.pdDesc" placeholder="请输入" />
    </a-form-model-item> -->
    <div class="title">非标项调整(选填)</div>
    <a-form-model-item label="调整材料：">
      <div class="table tableAdjust">
        <a @click="() => addItemAdjust()">新增调整材料</a>
        <a-table
          :columns="columnsAdjust"
          :data-source="dataAdjust"
          bordered
          key="maMId"
          :scroll="{ x: 1000 }"
        >
          <template
            v-for="col in [
              'maMId',
              'maQuantity',
              'maAdjFactor',
              'maAdjQuantity',
              'maAdjUnit',
              'maAdjPrice',
              'maDesc',
            ]"
            :slot="col"
            slot-scope="text, record"
          >
            <div :key="col">
              <a-select
                placeholder="请选择调整材料"
                v-if="col == 'maMId'"
                v-model="record.maMId"
                @change="(e) => handleChangeAdjust(e, text, col)"
              >
                <a-select-option
                  v-for="opt in ProjectMaterialListAdjust"
                  :key="opt.pmMId"
                  :value="opt.pmMId"
                  >{{ opt.tmaterial.mName }}</a-select-option
                >
              </a-select>

              <a-input
                placeholder="调整施工量"
                v-if="col == 'maAdjQuantity'"
                v-model="record.maAdjQuantity"
                :value="text"
              />
              <a-input
                placeholder="调整因子"
                v-if="col == 'maAdjFactor'"
                v-model="record.maAdjFactor"
                :value="text"
              />
              <!-- <a-input
                placeholder="材料用量"
                v-if="col == 'maQuantity'"
                v-model="record.maQuantity"
                :value="text"
              /> -->
              <span v-if="col == 'maQuantity'">
                {{ Math.ceil(record.maAdjQuantity * record.maAdjFactor) }}
              </span>
              <a-input
                placeholder="用量单位"
                v-if="col == 'maAdjUnit'"
                v-model="record.maAdjUnit"
                :value="text"
              />
              <a-input
                placeholder="调整费用"
                v-if="col == 'maAdjPrice'"
                v-model="record.maAdjPrice"
                :value="text"
              />
              <a-input
                placeholder="备注"
                v-if="col == 'maDesc'"
                v-model="record.maDesc"
                :value="text"
              />
            </div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="editable-row-operations">
              <span>
                <a-icon
                  type="plus"
                  @click="() => addItemAdjust(record.maMId)"
                />

                <a-icon
                  type="minus"
                  @click="() => deleteItemAdjust(record.maMId)"
                />
              </span>
            </div>
          </template>
        </a-table>
      </div>
    </a-form-model-item>
    <a-form-model-item
      :wrapper-col="{
        span: 14,
        offset: 4,
      }"
    >
      <a-button type="primary" @click="onSubmit"> 保存 </a-button>
      <a-button style="margin-left: 10px" @click="onClose"> 取消 </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { GetCompanies } from "@/api/device";
import {
  GetWorkProceList,
  GetProjectList,
  GetProjectMaterialList,
  GetProjectTypeList,
} from "@/api/apiJF/template";
import { GetPackageDetail } from "@/api/apiJF/package";

const columnsMain = [
  {
    title: "材料",
    dataIndex: "maMId",
    // width: "90px",
    scopedSlots: { customRender: "maMId" },
  },
  {
    title: "用量",
    dataIndex: "maQuantity",
    scopedSlots: { customRender: "maQuantity" },
    width: "100px",
  },
  {
    title: "单位",
    dataIndex: "maUnit",
    // scopedSlots: { customRender: "maQuantity" },
    width: "50px",
  },
  {
    title: "提供方",
    dataIndex: "maProvider",
    scopedSlots: { customRender: "maProvider" },
    width: "110px",
  },
  {
    title: "是否为增减项",
    dataIndex: "maAttribute",
    width: "100px",
    scopedSlots: { customRender: "maAttribute" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: "60px",
    scopedSlots: { customRender: "operation" },
    fixed: "right",
  },
];
const columnsAdjust = [
  {
    title: "调整材料",
    dataIndex: "maMId",
    scopedSlots: { customRender: "maMId" },
    // width: "100px",
  },
  {
    title: "调整施工量",
    dataIndex: "maAdjQuantity",
    scopedSlots: { customRender: "maAdjQuantity" },
    width: "110px",
  },
  {
    title: "调整因子",
    dataIndex: "maAdjFactor",
    width: "100px",
    scopedSlots: { customRender: "maAdjFactor" },
  },
  {
    title: "材料用量",
    dataIndex: "maQuantity",
    width: "100px",
    scopedSlots: { customRender: "maQuantity" },
  },
  {
    title: "用量单位",
    dataIndex: "maAdjUnit",
    // scopedSlots: { customRender: "maAdjUnit" },
    width: "100px",
  },
  {
    title: "调整费用(元)",
    dataIndex: "maAdjPrice",
    scopedSlots: { customRender: "maAdjPrice" },
    width: "115px",
  },
  {
    title: "备注",
    dataIndex: "maDesc",
    scopedSlots: { customRender: "maDesc" },
    width: "100px",
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: "90px",
    scopedSlots: { customRender: "operation" },
  },
];
const dataMain = []; //主材
const dataAux = []; //辅材
const dataAdjust = []; //调整因子

export default {
  props: {
    record: {
      type: String,
      default: {},
    },
    type: {
      type: String,
    },
  },
  data() {
    this.cacheDataMain = dataMain.map((item) => ({ ...item }));
    this.cacheDataAux = dataAux.map((item) => ({ ...item }));
    this.cacheDataAdjust = dataAdjust.map((item) => ({ ...item }));
    return {
      form: {
        // qdBcId: "",
        // qdWpId: "",
        // pdPId: "",
        // pdRatio: "",
        // pdDesc: "",
        qdId: "", //报价详情ID
        qdBcId: "", //分公司id
        qdQId: "", //报价id
        qdWpId: "", //施工工序id
        qdOrder: "", //序号
        qdPId: "", //施工项id
        qdPName: "", //项目名称
        qdUnit: "", //单位
        qdQuantity: "0", //数量
        qdPrice: "", //单价
        qdMoney: "", //金额
        qdDesc: "", //说明
        qdSupId: "", //父级id
        qdType: "", //施工类型
        qdCreateTime: "",
        qdDelFlag: "",
        // 平方米 米  间
        // qdCheapMoney: 0, //优惠金额
        // qdCheapPrice: 0, //优惠单价
        qdRatio: 0, //施工量占比
        qdAttribute: "", //是否属于增减项
        PtId: "", //施工项类型
      },
      qdUnitSelectValue: [
        { value: "平方米", label: "平方米" },
        { value: "米", label: "米" },
        { value: "间", label: "间" },
      ],
      horizontal: "horizontal",
      GetCompanyList: {},
      CompanyListid: [],
      // 查询参数
      queryParam: {
        qBcIds: "24",
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      rules: {
        qdBcId: [
          {
            required: true,
            message: "请选择所属公司名称",
            trigger: "change",
          },
          // {
          //   min: 3,
          //   max: 5,
          //   message: "Length should be 3 to 5",
          //   trigger: "blur",
          // },
        ],
      },
      WorkProceList: [], //施工工序
      ProjectTypeList: [], //施工项类型
      ProjectList: [], //施工项
      emitForm: {},
      columnsMain,
      columnsAdjust,
      dataMain,
      dataAux,
      dataAdjust,
      ProjectMaterialListMain: [], //主材--物料信息数据
      ProjectMaterialListAux: [], //辅材--物料信息数据
      ProjectMaterialListAdjust: [], //调整材料
      MaterialSupplier: [
        { value: 1, label: "公司" },
        { value: 2, label: "业主" },
      ], //材料提供者
      tableShow: false,
      oldTableData: [], //套餐详情的原始数据
      MaterialAttribute: [
        { value: "1", label: "否" },
        // { value: "2", label: "部分调整" },
        // { value: "3", label: "增加项" },
        // { value: "4", label: "减少项" },
      ],
      // 如果包含在施工项里才有减少项、增加项
      NoMaterialAttribute: [
        // { value: "1", label: "否" },
        // { value: "2", label: "部分调整" },
        { value: "3", label: "增加项" },
        { value: "4", label: "减少项" },
      ],
      // 如果不包含在施工项里只有增加项
      AddMaterialAttribute: [
        { value: "3", label: "增加项" },
        // { value: "4", label: "减少项" },
      ],
      AttributeList: [
        { value: "1", label: "套餐" },
        { value: "2", label: "部分调整" },
        { value: "3", label: "增加项" },
        { value: "4", label: "减少项" },
      ],
      AddAttributeList: [
        { value: "1", label: "套餐" },
        // { value: "2", label: "部分调整" },
        { value: "3", label: "增加项" },
        { value: "4", label: "减少项" },
      ],
      NoMaterialAttribute: [
        // { value: "1", label: "否" },
        // { value: "2", label: "部分调整" },
        { value: "3", label: "增加项" },
        { value: "4", label: "减少项" },
      ],
      MainIndex: 1,
      AuxIndex: 1,

      dataMainIds: [], //主材的所有材料id
      dataAuxIds: [], //辅材的所有材料id
    };
  },
  computed: {},
  created() {
    this.dataMain = [];
    this.dataAux = [];
    this.dataAdjust = [];
    this.cacheDataMain = [];
    this.cacheDataAux = [];
    this.cacheDataAdjust = [];
    this.GetCompaniesId();
    // this.form.qBcId = this.record.qBcId;

    let record = JSON.parse(this.record);
    console.log("record", record);

    // console.log("record.mainMaterials", record.mainMaterials);

    if (this.type == "edit") {
      for (let el in record) {
        this.form[el] = record[el];
        // console.log("el", el, this.form[el], record[el]);
      }
      this.form.qdAttribute = this.form.qdAttribute + "";
      // 施工项类型
      this.form.PtId = record.PtId
        ? record.PtId
        : record.tproject.tprojectType.ptId;
      let wpId = JSON.parse(this.record).wpId
        ? JSON.parse(this.record).wpId
        : JSON.parse(this.record).childrenWpId;
      // 主材
      this.dataMain =
        typeof record.mainMaterials == "string"
          ? JSON.parse(record.mainMaterials)
          : record.mainMaterials;
      // this.dataMain = record.mainMaterials
      //   ? JSON.parse(record.mainMaterials)
      //   : [];

      this.dataMain.forEach((el, index) => {
        el.id = index;
        el.maWpId = el.maWpId ? el.maWpId : wpId;
        el.maPId = el.maPId ? el.maPId : this.form.qdPId;
        el.maQid = el.maQid ? el.maQid : JSON.parse(this.record).qdQId; //报价id
        el.maAttribute = el.maAttribute + "";
        el.maContain = el.maContain
          ? el.maContain
          : el.tprojectMaterial.pmContain; //是否包含在施工项内
        el.maUnit = el.maUnit ? el.maUnit : el.tmaterial.mUnit; //单位
        el.maSalesPrice = el.maSalesPrice
          ? el.maSalesPrice
          : el.tmaterial.mSalesPrice; //单价
      });
      console.log(" this.dataMain", this.dataMain);
      this.MainIndex = this.dataMain.length + 1;
      this.cacheDataMain =
        this.dataMain.length > 0
          ? this.dataMain.map((item) => ({ ...item }))
          : [];
      // 辅材
      this.dataAux = record.subMaterials ? record.subMaterials : [];
      this.dataAux.forEach((el, index) => {
        el.maWpId = el.maWpId ? el.maWpId : wpId;
        el.maPId = el.maPId ? el.maPId : this.form.qdPId;
        el.maQid = el.maQid ? el.maQid : JSON.parse(this.record).qdQId; //报价id
        el.maAttribute = el.maAttribute + "";
        el.maContain = el.maContain
          ? el.maContain
          : el.tprojectMaterial.pmContain; //是否包含在施工项内
        el.maUnit = el.maUnit ? el.maUnit : el.tmaterial.mUnit; //单位
        el.maSalesPrice = el.maSalesPrice
          ? el.maSalesPrice
          : el.tmaterial.mSalesPrice; //单价
        el.id = index;
      });
      this.cacheDataAux = this.dataAux.map((item) => ({ ...item }));
      this.AuxIndex = this.dataAux.length + 1;
      // 调整因子
      this.dataAdjust = record.tmaterialAdjustments
        ? record.tmaterialAdjustments
        : [];
      this.cacheDataAdjust = this.dataAdjust.map((item) => ({ ...item }));
    }
    this.form.qdBcId = record.qdBcId;
    // 优惠单价

    if (record.qdWpId) {
      this.form.qdWpId = record.qdWpId;
      // for (let el in record) {
      //   this.form[el] = record[el];
      // }
      // console.log(" this.form", this.form);
    } else {
      this.form.qdWpId = record.wpId ? record.wpId : record.childrenWpId;
    }

    // 获取套餐详情
    this.GetPackageDetail(this.form.PtId, this.form.qdBcId);
    console.log(" 施工项类型 this.form.PtId", this.form.PtId);
  },
  watch: {
    "form.qdBcId": {
      handler(newValue) {
        // console.log("form.qdBcId", newValue);
        // // 获取施工工序
        this.GetWorkProceList(newValue);
        // 获取施工项类型
        this.GetProjectTypeList(newValue);
        // // 获取施工项
        // this.GetProjectList(newValue);
        // 获取物料信息--主材
        // this.GetProjectMaterialList(newValue);
        // this.GetProjectMaterialList_Aux(newValue);
      },
    },
    // 监听施工项类型
    "form.PtId": {
      handler(newValue) {
        // 获取施工项
        this.GetProjectList(this.form.qdBcId, newValue);
      },
    },

    "form.qdPId": {
      handler(newValue) {
        console.log("form.qdPId", newValue);
        let record = JSON.parse(this.record);
        // 获取物料信息--主材
        this.GetProjectMaterialList(this.form.qdBcId, newValue);
        // 获取物料信息--辅材
        this.GetProjectMaterialList_Aux(this.form.qdBcId, newValue);
        // 获取物料信息--全部
        this.GetProjectMaterialList_All(this.form.qdBcId, newValue);
        let index = -1;
        this.oldTableData.forEach((el) => {
          if (el.wpId == this.form.qdWpId) {
            el.children.forEach((item, i) => {
              console.log("item", item);
              console.log(" el.children", el.children, i);
              if (item.pdPId == newValue) {
                this.form.qdUnit = item.tproject.punit;
                // console.log("item.tproject.punit;", item.tproject);

                // this.form.qdCheapPrice = item.pdCheapPrice;
                this.form.qdPrice = item.pdPrice;
                index = i;
                // 原套餐里包含此条施工项  判断是否随施工量成比例变动pdFollowChange  1是 0否
                // 变动  施工量=建筑面积*比例
                // 不变   施工量=默认施工量
                // this.form.qdQuantity
                // console.log("item", item);
                if (item.pdFollowChange == "1") {
                  // 施工量=建筑面积*比例
                  this.form.qdQuantity = record.qarea * item.pdRatio;
                } else {
                  this.form.qdQuantity = item.pdQuantity;
                }
              } else {
                // this.form.qdQuantity = record.qarea;
              }
            });
          }
        });
        // console.log("index", index);

        setTimeout(() => {
          // // 改变施工项，获取施工项的单位和单价进行赋值
          this.ProjectList.forEach((el) => {
            if (el.pid == newValue) {
              this.form.qdUnit = el.punit;

              if (index == -1) {
                this.form.qdPrice = el.psalesPrice;
                // 原套餐里不包含此条施工项
                // console.log("原套餐里不包含此条施工项", el);

                this.form.qdQuantity = el.pdQuantity;
              }
              // this.form.qdPrice = el.psalesPrice;
              // console.log("el.pdCheapPrice", el);
            }
          });
        });
        // console.log("   this.form.qdUnit", this.form.qdUnit);
      },
      // immediate: true,
      deep: true,
    },
    dataAdjust: {
      handler(newValue) {
        // console.log("dataAdjust", newValue, this.ProjectMaterialListAdjust);
        // console.log("this.dataMain", this.dataMain);
        // setTimeout(() => {
        if (newValue != "") {
          newValue.forEach((el, index) => {
            let value = this.ProjectMaterialListAdjust.filter((item) => {
              return item.pmMId == el.maMId;
            });

            if (value.length > 0) {
              // console.log("value", value);
              // el.maAdjPrice = el.maQuantity * value[0].tmaterial.mSalesPrice;
              el.maAdjPrice =
                Math.round(
                  el.maQuantity * value[0].tmaterial.mSalesPrice * 100
                ) / 100;
              // el.maQuantity = el.maAdjQuantity * el.maAdjFactor;
              el.maQuantity = Math.ceil(el.maAdjQuantity * el.maAdjFactor);
              // console.log("dataAdjust---el.maQuantity", el.maQuantity);
            }
          });
          // console.log("dataAdjust", newValue);
        }
        // }, 100);
      },
      deep: true,
    },
    "form.qdQuantity": {
      handler(value, oldValue) {
        let record = JSON.parse(this.record);
        // console.log("form.qdQuantity", value, oldValue);

        // 不能立即监听
        if (oldValue !== "0") {
          // 监听施工量变化，计算材料里的用量
          // 主材
          let dataMain = [...this.dataMain];
          dataMain.forEach((el) => {
            // console.log("el", el);
            el.maAttribute = el.maAttribute + "";
            if (el.tprojectMaterial) {
              el.maLoss = el.tprojectMaterial.pmLoss;
              el.maUnitUsage = el.tprojectMaterial.pmUnitUsage;
              el.maContain = el.tprojectMaterial.pmContain; //是否包含在施工项中
              el.maUnit = el.tmaterial.mUnit; //单位
              el.maSalesPrice = el.tmaterial.mSalesPrice; //单价
            }
            let maLoss = el.maLoss * 1 + 1;
            // 材料用量=（1+损耗率)*单位用量*施工量
            // el.maQuantity = maLoss * el.maUnitUsage * value ;
            el.maQuantity = Math.ceil(maLoss * el.maUnitUsage * value);
            // console.log("主材随施工量改变", maLoss, el.maUnitUsage, value);
          });
          this.dataMain = dataMain;
          // 辅材
          let dataAux = [...this.dataAux];
          dataAux.forEach((el) => {
            // console.log("el", el);
            el.maAttribute = el.maAttribute + "";
            if (el.tprojectMaterial) {
              el.maLoss = el.tprojectMaterial.pmLoss;
              el.maUnitUsage = el.tprojectMaterial.pmUnitUsage;
              el.maContain = el.tprojectMaterial.pmContain; //是否包含在施工项中
              el.maUnit = el.tmaterial.mUnit; //单位
              el.maSalesPrice = el.tmaterial.mSalesPrice; //单价
            }
            let maLoss = el.maLoss * 1 + 1;
            // 材料用量=（1+损耗率)*单位用量*施工量
            // el.maQuantity = maLoss * el.maUnitUsage * value;
            el.maQuantity = Math.ceil(maLoss * el.maUnitUsage * value);
          });
          this.dataAux = dataAux;
        }
      },
      deep: true,
      // immediate: true,
    },

    // 监听主材，是否有增加项或减少项
    // 如果有增加项或减少项，则证明材料改变
    // 把默认为否的  是否属于增减项改为 部分调整
    dataMain: {
      handler(newValue) {
        // console.log("dataMain", newValue);

        if (this.type == "edit") {
          let hasIndex = -1;

          newValue.forEach((el, index) => {
            el.maAttribute = el.maAttribute + "";

            if (el.maAttribute == "3" || el.maAttribute == "4") {
              hasIndex = index;
            }
          });
          if (hasIndex > -1 && this.form.qdAttribute == "1") {
            // if (hasIndex > -1) {
            this.form.qdAttribute = "2";
          }
          // else if(this.form.qdAttribute == "1"){
          //   this.form.qdAttribute = "1";
          // }
          // console.log("修改主材", hasIndex, this.form.qdAttribute);
        }
        // 收集主材的材料id
        let dataMainIds = [];
        newValue.forEach((el) => {
          dataMainIds.push(el.maMId);
        });
        this.dataMainIds = dataMainIds;
        // 修改调整项列表
        // 获取物料信息--全部
        this.GetProjectMaterialList_All(this.form.qdBcId, this.form.qdPId);
      },
    },
    // 监听辅材
    dataAux: {
      handler(newValue) {
        if (this.type == "edit") {
          let hasIndex = -1;
          newValue.forEach((el, index) => {
            el.maAttribute = el.maAttribute + "";
            if (el.maAttribute == "3" || el.maAttribute == "4") {
              hasIndex = index;
            }
          });
          if (hasIndex > -1 && this.form.qdAttribute == "1") {
            // if (hasIndex > -1) {
            this.form.qdAttribute = "2";
          }
          //  else {
          //   this.form.qdAttribute = "1";
          // }
          // console.log("修改辅材", hasIndex, this.form.qdAttribute);
        }
        // 收集辅材的材料id
        let dataAuxIds = [];
        newValue.forEach((el) => {
          dataAuxIds.push(el.maMId);
        });
        this.dataAuxIds = dataAuxIds;
        // 修改调整项列表
        // 获取物料信息--全部
        this.GetProjectMaterialList_All(this.form.qdBcId, this.form.qdPId);
      },
    },
    // 监听是否为增减项
    "form.qdAttribute": {
      handler(newValue) {
        // 辅材
        let dataAux = this.dataAux;
        dataAux.forEach((el) => {
          el.maPAttribute = newValue;
        });
        this.dataAux = dataAux;
        // 主材
        let dataMain = this.dataMain;
        dataMain.forEach((el) => {
          el.maPAttribute = newValue;
        });
        this.dataMain = dataMain;
      },
    },
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            // this.CompanyList[el.bc_id] = el.bc_name;
          });
          this.GetCompanyList = res.data;
        })
        .then(() => {
          this.queryParam.qBcIds = this.CompanyListid.toString();
          // 获取物料信息
          // this.GetProjectMaterialList();
          // // 获取施工工序
          // this.GetWorkProceList();
          // 获取施工项
          // this.GetProjectList();
          // // 获取套餐类别
          // this.GetPackageTypeList();
        });
    },
    // 获取施工工序
    GetWorkProceList(wpBcIds) {
      let queryParam = {
        wpBcIds: wpBcIds ? wpBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["wpBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWorkProceList(params).then((res) => {
        // console.log("施工工序", res.data);
        this.WorkProceList = res.data;
        // console.log("this.WorkProceList", this.WorkProceList);
      });
    },
    // 获取施工项类型
    GetProjectTypeList(ptBcIds) {
      let queryParam = {
        ptBcIds: ptBcIds ? ptBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["ptBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectTypeList(params).then((res) => {
        // console.log("获取施工项类型", res.data);
        this.ProjectTypeList = res.data;
        // console.log("this.ProjectList", this.ProjectList);
      });
    },
    // 获取施工项
    GetProjectList(pBcIds, pPtId) {
      let queryParam = {
        pBcIds: pBcIds ? pBcIds : this.CompanyListid.toString(),
        pPtId: pPtId,
      };
      let orderParam = ["pBcIds", "pPtId"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectList(params).then((res) => {
        // console.log("施工项", res.data);
        this.ProjectList = res.data;
        // console.log("this.ProjectList", this.ProjectList);
      });
    },
    // 获取物料信息---主材
    GetProjectMaterialList(pmBcIds, pmPId) {
      let queryParam = {
        pmPId: pmPId ? pmPId : JSON.parse(this.record).qdPId,
        pmBcIds: pmBcIds ? pmBcIds : this.CompanyListid.toString(),
        pmType: "1",
      };
      let orderParam = ["pmPId", "pmBcIds", "pmType"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectMaterialList(params).then((res) => {
        // console.log("主材物料信息", res.data);
        this.ProjectMaterialListMain = res.data;

        // 如果是新增
        if (this.type == "add") {
          let data = [];
          let wpId = JSON.parse(this.record).wpId
            ? JSON.parse(this.record).wpId
            : JSON.parse(this.record).childrenWpId;
          res.data.forEach((el) => {
            // 包含在施工项内的材料自动展示出来
            if (el.pmContain == "1") {
              data.push({
                id: this.MainIndex,
                maMId: el.pmMId, //材料id
                maQuantity: "", //用量
                maProvider: 1, //提供方   1公司   2业主
                maType: "1", //主材
                maAttribute: "1", //是否为增减项
                maPAttribute: this.form.qdAttribute, //父级是否为增减项
                maBcId: JSON.parse(this.record).qdBcId, //分公司id
                maWpId: wpId,
                // maPId: JSON.parse(this.record).qdPId, //施工项id
                maPId: this.form.qdPId,
                maQid: JSON.parse(this.record).qdQId, //报价id
                maLoss: el.pmLoss, //损耗率
                maUnitUsage: el.pmUnitUsage, //单位用量
                maUnit: el.tmaterial.mUnit, //单位
                maSalesPrice: el.tmaterial.mSalesPrice, //单价
                maContain: el.pmContain, //是否包含在施工项内
              });
              this.MainIndex = this.MainIndex * 1 + 1;
            }
          });
          this.dataMain = data;
        }
      });
    },
    // 获取物料信息---辅材
    GetProjectMaterialList_Aux(pmBcIds, pmPId) {
      let queryParam = {
        pmPId: pmPId ? pmPId : JSON.parse(this.record).qdPId,
        pmBcIds: pmBcIds ? pmBcIds : this.CompanyListid.toString(),
        pmType: "2",
      };
      let orderParam = ["pmPId", "pmBcIds", "pmType"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectMaterialList(params).then((res) => {
        // console.log("辅材物料信息", res.data);
        this.ProjectMaterialListAux = res.data;
        // console.log("this.ProjectList", this.ProjectList);
        // 如果是新增
        if (this.type == "add") {
          let data = [];
          let wpId = JSON.parse(this.record).wpId
            ? JSON.parse(this.record).wpId
            : JSON.parse(this.record).childrenWpId;
          res.data.forEach((el) => {
            // 包含在施工项内的材料自动展示出来
            if (el.pmContain == "1") {
              data.push({
                id: this.AuxIndex,
                maMId: el.pmMId, //材料id
                maQuantity: "", //用量
                maProvider: 1, //提供方   1公司   2业主
                maType: "2", //辅材
                maAttribute: "1", //是否为增减项
                maPAttribute: this.form.qdAttribute, //父级是否为增减项
                maBcId: JSON.parse(this.record).qdBcId, //分公司id
                maWpId: wpId,
                // maPId: JSON.parse(this.record).qdPId, //施工项id
                maPId: this.form.qdPId,
                maQid: JSON.parse(this.record).qdQId, //报价id
                maLoss: el.pmLoss, //损耗率
                maUnitUsage: el.pmUnitUsage, //单位用量
                maUnit: el.tmaterial.mUnit, //单位
                maSalesPrice: el.tmaterial.mSalesPrice, //单价
                maContain: el.pmContain, //是否包含在施工项内
              });
              this.AuxIndex = this.AuxIndex * 1 + 1;
            }
          });
          this.dataAux = data;
        }
      });
    },
    // 获取物料信息--全部
    GetProjectMaterialList_All(pmBcIds, pmPId) {
      let queryParam = {
        pmPId: pmPId ? pmPId : JSON.parse(this.record).qdPId,
        pmBcIds: pmBcIds ? pmBcIds : this.CompanyListid.toString(),
        // pmType: "2",
      };
      let orderParam = ["pmPId", "pmBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectMaterialList(params).then((res) => {
        // console.log("全部物料信息", res.data);
        let ProjectMaterialListAdjust = [];
        //  主材id
        this.dataMainIds.forEach((el) => {
          let findItem = {};
          findItem = res.data.find((item) => {
            return item.pmMId == el;
          });
          ProjectMaterialListAdjust.push(findItem);
        });
        // 辅材id
        this.dataAuxIds.forEach((el) => {
          let findItem = {};
          findItem = res.data.find((item) => {
            return item.pmMId == el;
          });
          ProjectMaterialListAdjust.push(findItem);
        });
        // 去重
        var newArr = [];
        if (ProjectMaterialListAdjust.length > 0) {
          newArr = ProjectMaterialListAdjust.filter(function (item) {
            return newArr.includes(item.pmMId) ? "" : newArr.push(item.pmMId);
          });
          this.ProjectMaterialListAdjust = newArr;
        }

        // this.ProjectMaterialListAdjust = ProjectMaterialListAdjust;
      });
    },
    // 获取套餐详情
    GetPackageDetail(pdPtId, pdBcId) {
      let queryParam = {
        pdPtId: pdPtId,
        pdBcId: pdBcId,
      };
      let orderParam = ["pdPtId", "pdBcId"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPackageDetail(params).then((res) => {
        // console.log("套餐详情", res.data);
        let project = res.data.project;
        // 套餐详情里的原始数据
        this.oldTableData = res.data.project;
        // let newtabledata = [];
        // 根据施工项id获取此施工项的施工占比
        project.forEach((el) => {
          // console.log("111111111", el);
          if (el.wpId == this.form.qdWpId) {
            if (el.children) {
              el.children.forEach((item) => {
                // console.log("22222", item.pdPId, this.form.qdPId);
                if (item.pdPId == this.form.qdPId) {
                  // 施工项占比
                  this.form.qdRatio = item.pdRatio;
                  // 优惠单价
                  // this.form.qdCheapPrice = item.pdCheapPrice;
                  // 单价
                  this.form.qdPrice = item.pdPrice;
                  //
                  // 查询施工单位
                  // this.form.qdUnit = item.tproject ? item.tproject.punit : "";
                  // // this.form.
                  // // 根据套餐施工占比算出施工量
                  let record = JSON.parse(this.record);
                  // 根据施工占比计算得出的施工量
                  let oldQuantity = record.qarea * 1 * item.pdRatio * 1;
                  // 修改的施工项的施工量
                  let changeQuantity = this.form.qdQuantity * 1;
                  // console.log("oldQuantity", oldQuantity, changeQuantity);
                  // qdCheapMoney   优惠金额
                  // if (oldQuantity >= changeQuantity) {
                  //   this.form.qdCheapMoney =
                  //     changeQuantity * this.form.qdCheapPrice;
                  // } else {
                  //   let oldqdCheapMoney = oldQuantity * this.form.qdCheapPrice;
                  //   let addChange = changeQuantity - oldQuantity;
                  //   this.form.qdCheapMoney =
                  //     addChange * this.form.qdPrice * 1 + oldqdCheapMoney * 1;
                  // }
                  // console.log("优惠总金额", this.form.qdCheapMoney);
                  // console.log(" item.pdRatio", item);
                  // console.log("this.type", this.type);
                  // 如果施工量为0，则根据总施工量面积和施工占比计算施工量，
                  // 如果施工量不为0，则证明不是新增的，是人为修改之后的施工量，则不跟随施工总面积改变
                  if (this.type == "add") {
                    let record = JSON.parse(this.record);
                    // console.log("form.qdRatio", record.qarea, item.pdRatio);
                    this.form.qdQuantity = record.qarea * 1 * item.pdRatio; //计算施工量
                    // 查询施工单位
                    this.form.qdUnit = item.tproject ? item.tproject.punit : "";
                  }
                } else {
                  // 施工项占比
                  // this.form.qdRatio = 0;
                }
              });
            }
          } else {
            // // 优惠单价
            // this.form.qdCheapPrice = 0;
            // // 优惠金额
            // this.form.qdCheapPrice = 0;
          }
        });
      });
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // alert("submit!");
          // console.log("form", this.form);
          this.changeFormType(this.form);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    changeFormType(form) {
      let qdMoney = form.qdPrice * form.qdQuantity;
      // 主材总金额
      let qdMPrice = 0;
      this.dataMain.forEach((el) => {
        this.ProjectMaterialListMain.forEach((item) => {
          if (el.maMId == item.pmMId) {
            qdMPrice =
              qdMPrice * 1 + item.tmaterial.mSalesPrice * el.maQuantity;
          }
        });
      });
      let data = {
        wpId: form.qdWpId,
        children: [
          {
            qdPId: form.qdPId,
            qdDesc: form.qdDesc,
            qdPrice: form.qdPrice,
            qdQuantity: form.qdQuantity, //施工量
            qdUnit: form.qdUnit, //计量单位
            qdRatio: form.qdRatio, //施工量占比
            childrenWpId: form.qdWpId,
            qdBcId: form.qdBcId,
            qdMoney: qdMoney.toFixed(2) * 1, //总金额
            qdMPrice: qdMPrice.toFixed(2) * 1, //主材总金额
            // qdCheapMoney: form.qdCheapMoney.toFixed(2) * 1, //优惠总金额
            // qdCheapPrice: form.qdCheapPrice, //优惠单价
            qdAttribute: form.qdAttribute, //是否属于增减项
            mainMaterials: JSON.stringify(this.dataMain), //主材
            subMaterials: this.dataAux, //辅材
            tmaterialAdjustments: this.dataAdjust, //调整因子
            PtId: form.PtId, //施工项类型
            tbranchcompany: {
              bcId: form.qdBcId,
            },
          },
        ],
      };

      this.emitForm = JSON.stringify(data);
      // console.log("this.emitForm", JSON.stringify(data));
      this.$emit("tabledataChange", this.emitForm);
    },
    handleChange() {
      this.$forceUpdate();
    },
    onClose() {
      this.$emit("onClose");
      this.emitForm = {};
    },
    // 主材
    addItemMain(key) {
      // console.log("addItemMain", key);
      let wpId = JSON.parse(this.record).wpId
        ? JSON.parse(this.record).wpId
        : JSON.parse(this.record).childrenWpId;
      this.dataMain.push({
        id: this.MainIndex,
        maMId: "", //材料id
        maQuantity: "", //用量
        maProvider: 1, //提供方   1公司   2业主
        maType: "1", //主材
        maAttribute: "3", //是否为增减项
        maPAttribute: this.form.qdAttribute, //父级是否为增减项
        maBcId: JSON.parse(this.record).qdBcId, //分公司id
        maWpId: wpId,
        // maPId: JSON.parse(this.record).qdPId, //施工项id
        maPId: this.form.qdPId,
        maQid: JSON.parse(this.record).qdQId, //报价id
        maLoss: "", //损耗率
        maUnitUsage: "", //单位用量
        maUnit: "", //单位
        maSalesPrice: "", //单价
        maContain: "", //是否包含在施工项中
      });
      this.MainIndex = this.MainIndex * 1 + 1;
      this.cacheDataMain = this.dataMain.map((item) => ({ ...item }));
    },
    deleteItemMain(key, maAttribute) {
      // console.log("deleteItemMain", key);

      const dataMain = [...this.dataMain];
      this.dataMain = dataMain.filter((item) => {
        return item.id != key;
      });
      this.cacheDataMain = this.dataMain.map((item) => ({ ...item }));
      // console.log("this.dataMain", this.dataMain);
    },
    handleChangeMain(value, key, column) {
      // console.log(
      //   "handleChangeMain",
      //   "value=",
      //   value,
      //   "key=",
      //   key,
      //   "column=",
      //   column
      // );
      // 根据材料id，查询此材料是否包含在施工项(包含在施工项时，是否为增减项为否）、材料用量=施工量*（1+损耗率pmLoss）*单位用量pmUnitUsage

      const newData = [...this.dataMain];
      console.log("newData", newData);
      const target = newData.find((item) => {
        // console.log("handleChange-target", value, item);
        // return value == item[column];
        return key == item.id;
      });
      if (column == "maMId") {
        let maAttribute = ""; //是否为增减项  1否 3增加项  4减少项
        let maQuantity = 0; //材料用量
        let maLoss = "0"; //损耗率
        let maUnitUsage = "0"; //单位用量
        let maUnit = ""; //单位
        let maSalesPrice = "0"; //单价
        let maContain = ""; //是否包含在施工项中
        this.ProjectMaterialListMain.forEach((el) => {
          if (el.pmMId == value) {
            // 1是 0否  是否包含在施工项内  如果为是，就是包含在施工项里，，
            // if (el.pmContain == "0") {
            //   maAttribute = "1";
            // } else {
            //   maAttribute = "3";
            // }
            if (el.pmContain == "0") {
              maAttribute = "3";
            } else {
              // maAttribute = "3";
            }
            //  材料用量=施工量*（1+损耗率pmLoss）*单位用量pmUnitUsage

            let pmLoss = el.pmLoss ? el.pmLoss * 1 + 1 : 1; //损耗
            maLoss = el.pmLoss;
            maUnitUsage = el.pmUnitUsage;
            let qdQuantity = this.form.qdQuantity ? this.form.qdQuantity : 0; //总施工量
            // maQuantity = qdQuantity * pmLoss * el.pmUnitUsage;
            maQuantity = Math.ceil(qdQuantity * pmLoss * el.pmUnitUsage);

            maUnit = el.tmaterial.mUnit;
            maSalesPrice = el.tmaterial.mSalesPrice;
            maContain = el.pmContain;
            // console.log("损耗率=", el.pmLoss, "单位用量=", el.pmUnitUsage, el);
          }
        });
        // 如果之前的主材列表里已经有这个材料，那新增的这条数得是否为增减项，不能再为否
        // 判断是否有两个及两个以上的相同施工项
        let hasNums = 0;
        newData.forEach((item) => {
          if (item.maMId == value) {
            hasNums = hasNums * 1 + 1;
          }
        });

        // 先默认为增加项
        if (hasNums > 1) {
          maAttribute = "3";
        }
        if (column != "maAttribute" && column == "maMId") {
          target.maAttribute = maAttribute;
        }
        if (column != "maQuantity" && column == "maMId") {
          target.maQuantity = maQuantity;
        }
        // target.id = value + "" + maAttribute;
        target.maLoss = maLoss;
        target.maUnitUsage = maUnitUsage;
        target.maUnit = maUnit;
        target.maSalesPrice = maSalesPrice;
        target.maContain = maContain;
      }
      // console.log("target", target);
      if (target) {
        target[column] = value;

        // target.tsupplier.sid = value;
        // target.msPlace = this.MaterialSupplierResult(value);
        this.dataMain = newData;
        // console.log("handleChange2", target[column]);
      }
      // console.log("this.data", this.data);
    },
    // 辅材
    addItemAux(key) {
      // console.log("addItemAux", key);
      let wpId = JSON.parse(this.record).wpId
        ? JSON.parse(this.record).wpId
        : JSON.parse(this.record).childrenWpId;
      this.dataAux.push({
        id: this.AuxIndex,
        maMId: "", //材料id
        maQuantity: "", //用量
        maProvider: 1, //提供方   1公司   2业主
        maType: "2", //辅材
        maAttribute: "3", //是否为增减项
        maPAttribute: this.form.qdAttribute, //父级是否为增减项
        maBcId: JSON.parse(this.record).qdBcId, //分公司id
        maWpId: wpId,
        // maPId: JSON.parse(this.record).qdPId, //施工项id
        maPId: this.form.qdPId,
        maQid: JSON.parse(this.record).qdQId, //报价id
        maLoss: "", //损耗率
        maUnitUsage: "", //单位用量
        maUnit: "", //单位
        maSalesPrice: "", //单价
        maContain: "", //是否包含在施工项中
      });
      this.AuxIndex = this.AuxIndex * 1 + 1;
      this.cachedataAux = this.dataAux.map((item) => ({ ...item }));
    },
    deleteItemAux(key) {
      // console.log("deleteItemAux", key);
      // if (this.dataAux.length === 1) {
      //   return;
      // }
      const dataAux = [...this.dataAux];
      this.dataAux = dataAux.filter((item) => item.id != key);
      this.cachedataAux = this.dataAux.map((item) => ({ ...item }));
      // console.log("this.dataAux", this.dataAux);
    },
    handleChangeAux(value, key, column) {
      // console.log("handleChangeAux", value, key, column);

      const newData = [...this.dataAux];
      // console.log("newData", newData);
      const target = newData.find((item) => {
        // return value == item[column];
        return key == item.id;
      });
      if (column == "maMId") {
        let maAttribute = ""; //是否为增减项  1否 3增加项  4减少项
        let maQuantity = 0; //材料用量
        let maLoss = "0"; //损耗率
        let maUnitUsage = "0"; //单位用量
        let maUnit = ""; //单位
        let maSalesPrice = ""; //单价
        let maContain = ""; //是否包含在施工项中
        this.ProjectMaterialListAux.forEach((el) => {
          if (el.pmMId == value) {
            // 1是 0否
            // if (el.pmContain == "0") {
            //   maAttribute = "1";
            // }
            if (el.pmContain == "0") {
              maAttribute = "3";
            } else {
              // maAttribute = "3";
            }
            //  材料用量=施工量*（1+损耗率pmLoss）*单位用量pmUnitUsage
            let pmLoss = el.pmLoss ? el.pmLoss * 1 + 1 : 1; //损耗
            let qdQuantity = this.form.qdQuantity ? this.form.qdQuantity : 0; //总施工量
            maLoss = el.pmLoss;
            maUnitUsage = el.pmUnitUsage;
            // maQuantity = qdQuantity * pmLoss * el.pmUnitUsage;
            maQuantity = Math.ceil(qdQuantity * pmLoss * el.pmUnitUsage);
            maUnit = el.tmaterial.mUnit;
            maSalesPrice = el.tmaterial.mSalesPrice;
            maContain = el.pmContain;
            // console.log("损耗率=", el.pmLoss, "单位用量=", el.pmUnitUsage, el);
          }
        });
        // 如果之前的主材列表里已经有这个材料，那新增的这条数得是否为增减项，不能再为否
        // 判断是否有两个及两个以上的相同施工项
        let hasNums = 0;
        newData.forEach((item) => {
          if (item.maMId == value) {
            hasNums = hasNums * 1 + 1;
          }
        });
        // 先默认为增加项
        if (hasNums > 1) {
          maAttribute = "3";
        }
        if (column != "maAttribute" && column == "maMId") {
          target.maAttribute = maAttribute;
        }
        if (column != "maQuantity" && column == "maMId") {
          target.maQuantity = maQuantity;
        }
        // target.id = value + "" + maAttribute;
        target.maLoss = maLoss;
        target.maUnitUsage = maUnitUsage;
        target.maUnit = maUnit;
        target.maSalesPrice = maSalesPrice;
        target.maContain = maContain;
      }
      if (target) {
        target[column] = value;
        this.dataAux = newData;
      }
      console.log(" this.dataAux", this.dataAux);
    },
    // 非标项调整
    addItemAdjust(key) {
      // console.log("addItemAdjust", key);
      this.dataAdjust.push({
        maMId: "", //材料id
        maAdjQuantity: "", //调整施工量
        maAdjFactor: "", //调整因子
        maQuantity: "", //材料用量
        maAdjUnit: "", //用量单位
        maAdjPrice: "", //调整费用
        maDesc: "", //备注
        // maProvider: 1, //提供方   1公司   2业主
        // maType: "2", //辅材
        maBcId: JSON.parse(this.record).qdBcId, //分公司id
        maWpId: JSON.parse(this.record).childrenWpId,
        maPId: JSON.parse(this.record).qdPId, //施工项id
        maQid: JSON.parse(this.record).qdQId, //报价id
      });
      this.cacheDataAdjust = this.dataAdjust.map((item) => ({ ...item }));
    },
    deleteItemAdjust(key) {
      // console.log("deleteItemAdjust", key);
      // 只有一项的时候不能被删除
      // if (this.dataAdjust.length === 1) {
      //   return;
      // }
      const dataAdjust = [...this.dataAdjust];
      this.dataAdjust = dataAdjust.filter((item) => item.maMId !== key);
      this.cacheDataAdjust = this.dataAdjust.map((item) => ({ ...item }));
      // console.log("this.dataAdjust", this.dataAdjust);
    },
    handleChangeAdjust(value, key, column) {
      // console.log("handleChangeAdjust", value, key, column);
      const newData = [...this.dataAdjust];
      // console.log("newData", newData);
      let isHasIndex = -1;
      let hasIndex = -1;
      newData.forEach((item, index) => {
        if (value == item[column]) {
          isHasIndex = isHasIndex + 1;
          hasIndex = index;
        }
      });
      // console.log("isHasIndex", isHasIndex);
      // console.log("newData", newData, hasIndex);

      if (isHasIndex > 0) {
        // this.$message.error("请不要重复选择非标调整项");
        newData[hasIndex].maMId = "";
      } else {
        // console.log("222222");

        const target = newData.find((item) => {
          return value == item[column];
        });
        // console.log("target", target);

        if (target) {
          target[column] = value;
          this.ProjectMaterialListAdjust.forEach((el) => {
            if (el.pmMId == value) {
              target["maAdjUnit"] = el.tmaterial.mUnit;
              // console.log("非标项调整--el", el);
            }
          });
          this.dataAdjust = newData;
        }
      }

      // this.dataAdjust = newData;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-row {
  display: flex;
  // width: 90%;
  width: 100%;
}
/deep/.anticon {
  margin-right: 5px;
}
/deep/ .ant-form-item-children {
  display: flex;
  // text-align: center;
  // justify-content: center;
  align-items: center;
  // justify-content: right;
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
.title {
  font-weight: 800;
  margin: 20px 0;
  font-size: 18px;
}
.table {
  text-align: left;
  margin-top: 50px;
  margin-left: -100px;
  width: 600px;
}
/deep/.table .ant-form-item-control-wrapper {
  width: 100%;
  margin-left: 50px;
}
.editable-cell {
  position: relative;
}
.tableAdjust {
  // /deep/.ant-form-item-children {
  margin-left: -130px !important;
  // margin-left: -220px !important;
  // }
}
</style>